.carousel .thumbs.animated {
    text-align: center;
}

/* 
>는 자식 선택자(Child Selector)로, .carousel 요소의 직접적인 자식 요소만을 선택하게 됩니다. 만약 > 대신 공백을 사용했다면, .carousel 요소의 모든 하위 요소(즉, 자식, 자식의 자식, 등등)가 선택 대상이 될 수 있습니다.
.thumbs.animated는 thumbs와 animated 두 개의 클래스가 모두 적용된 요소를 선택합니다.


HTML 요소가 클래스 A 또는 클래스 B를 가진 경우를 선택하려면 쉼표 ,를 사용하여 여러 선택자를 나열할 수 있습니다.
.A, .B {
  스타일 속성/ 
}


'A 클래스와 B 클래스를 동시에 가진 요소'를 선택하려면 선택자를 붙여서 사용해야 합니다:
.A.B {
    스타일 속성/ 
}


CSS 선택자에서 공백(space)는 특정 의미를 가지고 있습니다. 클래스 이름을 연결하여 사용할 때 (즉, 한 요소가 여러 클래스를 동시에 가진 경우를 지정할 때) 공백 없이 사용해야 합니다. 예를 들면 다음과 같습니다:


.A.B { 스타일 속성  }
이렇게 작성하면 .A 클래스와 .B 클래스를 동시에 가진 요소에 스타일을 적용합니다.

만약 .A .B와 같이 중간에 공백이 있다면, 이는 다른 의미를 가집니다. 이 선택자는 .A 클래스를 가진 요소의 자손 중 .B 클래스를 가진 요소를 선택합니다.


.A .B { 스타일 속성 }
이 경우 .A 클래스를 가진 요소 안에 있는 .B 클래스를 가진 요소에 스타일이 적용됩니다. .A와 .B가 동일한 요소에 적용되어 있는 경우에는 스타일이 적용되지 않습니다.

따라서 공백의 유무는 선택자의 의미를 변경하므로 주의해야 합니다.
*/