@import "react-datepicker/dist/react-datepicker.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NanumSquareR';
  src: url('./fonts/NanumSquare/NanumFontSetup_TTF_SQUARE/NanumSquareR.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NanumSquareB';
  src: url('./fonts/NanumSquare/NanumFontSetup_TTF_SQUARE/NanumSquareB.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NanumSquareEB';
  src: url('./fonts/NanumSquare/NanumFontSetup_TTF_SQUARE/NanumSquareEB.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NanumSquareNeoH';
  src: url('./fonts/NanumSquareNeo/NanumSquareNeo/TTF/NanumSquareNeo-eHv.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NanumSquareNeoB';
  src: url('./fonts/NanumSquareNeo/NanumSquareNeo/TTF/NanumSquareNeo-cBd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NanumSquareNeoEB';
  src: url('./fonts/NanumSquareNeo/NanumSquareNeo/TTF/NanumSquareNeo-dEb.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}