@keyframes slideDown {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.nav-item1 {
  /* position: relative; */
  cursor: pointer;
}
/* .dropdown-menu {
  position: absolute;
  top: 100%; 
  left: 0;

} */
/* 드롭다운 메뉴를 기본적으로 숨깁니다. */
.nav-item1 .dropdown-menu {
  display: none;
    animation: slideDown 0.5s ease forwards; /* 애니메이션 적용 */
}

/* 드롭다운 메뉴의 부모 요소에 마우스를 올렸을 때 메뉴를 표시합니다. */
.nav-item1:hover .dropdown-menu {
  display: block;
  position: absolute;
}

/* 드롭다운 메뉴의 스타일 설정 */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 0.25rem;
}

/* 드롭다운 메뉴 아이템의 스타일 */
.dropdown-menu li a {
  display: block;
  /* text-decoration: none; */
  padding: 0.5rem 1rem;
  /* clear: both; */
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu li a:hover {
  color: #003366;
  /* background-color: #007bff; */
}

.active-link {
  color: #1565c0 !important;
  text-decoration: none;
}

/* 메뉴 항목 기본 스타일 */
.nav-link {
  position: relative;
  /* color: black; */
  text-decoration: none;
}

/* 호버 시 글자색 변경 */
.nav-link:hover {
  color: #1565c0 !important; /* 호버 시 글자색 */
}

/* 밑줄 애니메이션  호버 안했을때 상태.*/
.nav-link::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0px; /* 밑줄의 위치 조정 */
  border-bottom: 3px solid #1976d2; /* 밑줄 색상 */
  transition: all 0.3s;
}

/* 호버 시 밑줄 애니메이션 활성화 */
.nav-link:hover::after {
  left: 0;
  right: 0;
}

.navbar-nav {
  gap: 5rem; /* 초기 간격 설정 */
}

/* 화면 너비가 1250px 이하일 때의 스타일 */
@media (max-width: 1250px) {
  .navbar-nav {
    gap: 2rem; /* 간격 조정 */
  }
}