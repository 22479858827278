/* 전역에 기본 애니메이션 정의 */
@keyframes slideInFromRight01 {
  from {
    opacity: 0;
    transform: translate(-150px, 180px);
  }
  to {
    opacity: 1;
    transform: translate(-200px, 180px);
    
  }
}

@keyframes slideInFromRight01-1300 {
  from {
    opacity: 0;
    transform: translate(0px, 180px);
  }
  to {
    opacity: 1;
    transform: translate(-50px, 180px);
  }
}

@keyframes slideInFromRight01-1000 {
  from {
    opacity: 0;
    transform: translate(50px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* 오른쪽에서 왼쪽으로 나타나는 기본 효과 */
.slide-in-right-01 {
  animation: slideInFromRight01 1.5s ease-in-out forwards;
}

/* 1200px 미만에서 적용될 애니메이션 변경 */

@media (max-width: 1300px) {
  .slide-in-right-01 {
    animation-name: slideInFromRight01-1300;
  }
}

/* 1000px 미만에서 적용될 애니메이션 변경 */
@media (max-width: 1000px) {
  .slide-in-right-01 {
    animation-name: slideInFromRight01-1000;
  }
}





/* 전역에 기본 애니메이션 정의 */
@keyframes slideInFromLeft01 {
  from {
    opacity: 0;
    transform: translate(-50px, -100px);
  }
  to {
    opacity: 1;
    transform: translate(0px, -100px);
  }
}

@keyframes slideInFromLeft01-1200 {
  from {
    opacity: 0;
    transform: translate(-50px, -100px);
  }
  to {
    opacity: 1;
    transform: translate(0px, -100px);
  }
}

@keyframes slideInFromLeft01-1000 {
  from {
    opacity: 0;
    transform: translate(-50px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* 왼쪽에서 오른쪽으로 나타나는 기본 효과 */
.slide-in-left-01 {
  animation: slideInFromLeft01 1.5s ease-in-out forwards;
  
}

/* 1200px 미만에서 적용될 애니메이션 변경 */
@media (max-width: 1200px) {
  .slide-in-left-01 {
    animation-name: slideInFromLeft01-1200;
    
  }
}

/* 1000px 미만에서 적용될 애니메이션 변경 */
@media (max-width: 1000px) {
  .slide-in-left-01 {
    animation-name: slideInFromLeft01-1000;
  }
}



/* 전역에 기본 애니메이션 정의 */
@keyframes slideInFromRight02 {
  from {
    opacity: 0;
    transform: translate(50px, -60px);
  }
  to {
    opacity: 1;
    transform: translate(0px, -60px);
    
  }
}

@keyframes slideInFromRight02-1200 {
  from {
    opacity: 0;
    transform: translate(50px, -60px);
  }
  to {
    opacity: 1;
    transform: translate(0px, -60px);
  }
}

@keyframes slideInFromRight02-1000 {
  from {
    opacity: 0;
    transform: translate(50px, 0px);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

/* 오른쪽에서 왼쪽으로 나타나는 기본 효과 */
.slide-in-right-02 {
  animation: slideInFromRight02 1.5s ease-in-out forwards;
}

/* 1200px 미만에서 적용될 애니메이션 변경 */
@media (max-width: 1200px) {
  .slide-in-right-02 {
    animation-name: slideInFromRight02-1200;
  }
}

/* 1000px 미만에서 적용될 애니메이션 변경 */
@media (max-width: 1000px) {
  .slide-in-right-02 {
    animation-name: slideInFromRight02-1000;
  }
}




@keyframes fadeIn {
  from {
    opacity: 0; /* 완전히 투명 */
    transform: translateY(10); /* 아래에서 위로 이동 */
  }
  to {
    opacity: 1; /* 완전히 불투명 */
    transform: translateY(0); /* 원래 위치 */
  }
}

.fade-in-slow {
  animation: fadeIn 1.5s ease-in-out; /* 1초 동안 서서히 나타남 */
  opacity: 1;
}
.fade-in {
  animation: fadeIn 1s ease-in-out; /* 1초 동안 서서히 나타남 */
  opacity: 1;
}

.hidden {
  opacity: 0;
}

@keyframes slide-up {
  from {
    transform: translateY(7%); /* 시작 위치: Y축으로 100%만큼 아래 */
    opacity: 0; /* 시작 시 투명도: 0 (투명) */
  }
  to {
    transform: translateY(0); /* 끝 위치: 원래 위치 */
    opacity: 1; /* 끝 시 투명도: 1 (불투명) */
  }
}

@keyframes slide-little-up {
  from {
    transform: translateY(1%); /* 시작 위치: Y축으로 100%만큼 아래 */
    opacity: 0; /* 시작 시 투명도: 0 (투명) */
  }
  to {
    transform: translateY(0); /* 끝 위치: 원래 위치 */
    opacity: 1; /* 끝 시 투명도: 1 (불투명) */
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
